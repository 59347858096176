import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Nav from '../components/nav';
import MobileNav from '../components/mobileNav';
import SEO from '../components/seo';
import Footer from '../components/footer';
import Me from '../images/me.jpg';

import './about.scss';

const About = (props) => (
  <Layout>
    <SEO title='About' description='About me'/>
    <Nav />
    <MobileNav />
    
    <div className="about">
      <div className="__hero">
        <img className="__hero-image" src={Me} alt="me" />
        <h1 className='__hero-title'>Hi, I'm Robert</h1>
      </div>
      <div className="__content">
        <div className="__section-one">
          <h1 className="__section-title">Who I am</h1>
          <p>
            Highly innovative, passionate and straight shooting Product UI/UX Design with high proficiencies in problem-solving,
            visual UI design, UX design, user-centered design principles and methodologies, product development, and front-end development.
          </p>
          <p>
            A unique skill-set that spans various disciplines from digital marketing to hands-on UI/UX design to front-end development
            (HTML/CSS/JavaScript/TypeScript/React). I have several years of experience in understanding and developing products from concept ideas into production.
            Highly effective written and verbal communicator with exceptional problem-solving skills.
          </p>
        </div>
        <div className="__section-two">
          <h1 className="__section-title">What I do</h1>
          <p>
            I specialize in Users. From Interfaces to Experiences my goal is to create things that just work.
            I Design pixel based solutions for the Web & Product and Develop websites and web applications using HTML, CSS, JavaScript,
            and a touch of Jedi Mind Powers, See for yourself.
          </p>
          <p>
            Whether you're a startup looking for help with your app, a non-profit that wants to make an impact with your website,
            or a small business that needs a full branding makeover, I would love to help you find the right solution that changes your game.
          </p>
        </div>
      </div>
      <div className="__work-history">
        <h1>Working Experience</h1>
        <div className="__work-history-list">
          <div className="__row-one">
            <p className="__name">Launch That</p>
            <p className="__date">2019 - Present</p>
          </div>
          <div className="__row-two">
            <p className="__role">DESIGNER & DEVELOPER</p>
            <a className="__website" href="https://www.launchthat.com/" target="_blank" rel="noopener noreferrer">https://www.launchthat.com/</a>
          </div>
        </div>
        <div className="__work-history-list">
          <div className="__row-one">
            <p className="__name">LUNAR</p>
            <p className="__date">2018 - 2019</p>
          </div>
          <div className="__row-two">
            <p className="__role">DESIGNER & DEVELOPER</p>
            <a className="__website" href="https://www.belunar.com/" target="_blank" rel="noopener noreferrer">https://www.belunar.com/</a>
          </div>
        </div>
        <div className="__work-history-list">
          <div className="__row-one">
            <p className="__name">Launch That</p>
            <p className="__date">2017 - 2018</p>
          </div>
          <div className="__row-two">
            <p className="__role">DESIGNER & DEVELOPER</p>
            <a className="__website" href="https://www.launchthat.com/" target="_blank" rel="noopener noreferrer">https://www.launchthat.com/</a>
          </div>
        </div>
        <div className="__work-history-list">
          <div className="__row-one">
            <p className="__name">FREELANCER</p>
            <p className="__date">2017 - PRESENT</p>
          </div>
          <div className="__row-two">
            <p className="__role">DESIGNER & DEVELOPER</p>
            <a className="__website" href="https://robertlesterweb.com/" target="_blank" rel="noopener noreferrer">https://robertlesterweb.com/</a>
          </div>
        </div>
      </div>
      <div className="__contact-section">
        <h2 className="__contact-title">Want to work with me?</h2>
        <div className='__submit-container'>
          <Link to='/contact'>
            <button type='submit' className='__submit-button'>
              Contact
            </button>
          </Link>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default About

export const pageQuery = graphql` 
 query WorkHistoryQuery {
  allContentfulWorkHistory {
    edges {
      node {
        id
        company
        dateRange
        role
        website
      }
    }
  }
 }
`